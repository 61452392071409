import jsonFromDocument from "cerulean/src/JsonFromDocument";
import { components } from "../api_utils/schema";

type User = components["schemas"]["SimpleUser"];

export function useUserConfiguration(): {
  features: string[];
  permissions: string[];
  currentUser: User;
  isNarmi: boolean;
  isSuperUser: boolean;
} {
  // this might smell like a useless hook, but we might be moving to launchdarkly
  // and having a hook will future-proof us a little bit.
  const currentUser = jsonFromDocument("current_user");
  return {
    features: Object.entries(
      jsonFromDocument("institution_features", "null") ??
        jsonFromDocument("features"),
    )
      .filter(([, value]) => value)
      .map(([key]) => key),
    permissions: jsonFromDocument("user_permissions"),
    currentUser,
    isNarmi: currentUser?.is_narmi || false,
    isSuperUser: currentUser?.is_superuser || false,
  };
}
